.callBtn {
  background: #4296ff;
  color: white;
  border: 1px solid #4296ff;
  margin: 0 10px;
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 15px;
  width: 100px;
  position: absolute;
  right: 30px;
  top: 10px;
}

a.callBtn.btn-success.btn-sm {
  background: #00b900;
  color: white;
  border: 1px solid #00b900;
  text-align: center;
  font-size: 16px;
  font-weight: bolder;
  text-decoration: auto;
}

.emoji-picker-react {
  width: 100% !important;
  margin-top: 10px;
}

.emoji-picker-react .emoji-categories button.icn-smileys_people {
  background-image: url('../../assets/images/smile.png');
}
.emoji-picker-react .emoji-categories button.icn-animals_nature {
  background-image: url('../../assets/images/bear.png');
}
.emoji-picker-react .emoji-categories button.icn-food_drink {
  background-image: url('../../assets/images/cup.png');
}
.emoji-picker-react .emoji-categories button.icn-travel_places {
  background-image: url('../../assets/images/car.png');
}
.emoji-picker-react .emoji-categories button.icn-activities {
  background-image: url('../../assets/images/football.png');
}
.emoji-picker-react .emoji-categories button.icn-objects {
  background-image: url('../../assets/images/lamp.png');
}
.emoji-picker-react .emoji-categories button.icn-symbols {
  background-image: url('../../assets/images/hastag.png');
}
.emoji-picker-react .emoji-categories button.icn-flags {
  background-image: url('../../assets/images/flags.png');
}
